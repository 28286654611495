import { useState, useEffect } from "react"

const TestimonialsGroup  = ({ data }) => {

    const [isClient, setIsClient] = useState(false)
    
    useEffect(() => {
        setIsClient(true)
    }, [])

    return (
    <div className="container flex flex-col items-center md:flex-row gap-20 px-8 align-top mt-6">
        <div className="flex-auto">
        <h2 className="flex mb-2 font-cursive text-crimson text-2xl">{"Some Words From Our Customers"}</h2>
        { isClient && <div className="hc-widget">
            <div data-hc="review-journal"></div>
        </div>}
        </div>
    </div>);
}

export default TestimonialsGroup